import React, { useState, useEffect, useRef } from 'react';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import ApplicationLogo from '../../../components/ApplicationLogo/ApplicationLogo';
import BrowseNavigation from '../../../components/BrowseNavigation/BrowseNavigation';
import MainSearch from '../../../components/MainSearch/MainSearch';
import UserAction from '../../../components/UserAction/UserAction';
import Arrow from '../../../components/Arrow/Arrow';
import { ReactComponent as AgeVerifiedIcon } from '../../../images/ageVerifiedIcon.svg';

import { createJoinLinkFromLastCode } from '../../../links';
import { isUserLoggedIn } from '../../../services/token-service/token.service';
import { getUserNATSCode } from '../../../services/nats-service/nats.service';
import { UsersController } from '../../../controllers/users-controller/users.controller';
import { showMyAccountPage } from '../../../services/navigation/navigation.service';
import { SignInController } from '../../../controllers/sign-in-controller/sign-in.controller';
import { getCookie, isWeb } from '../../../services/util-service/util.service';
import { DashboardController } from '../../../controllers/dashboard-controller/DashboardController';
import NavigationLinks from '../../../components/NavigationLinks/NavigationLinks';

import './MainDashboardHeader.scss';

const MainDashboardHeader = (props) => {
  const { handleSearchDropdownValue, toggleMobileMenu } = props;
  const [desktopSearchVisible, setDesktopSearchVisible] = useState(false);
  const ageVerContentRef = useRef(null);
  const ageVerVisible = useRef(false);
  const { t } = useTranslation();
  const userLoggedIn = isUserLoggedIn();
  const { header_links, browse_config } = DashboardController.getWhitelabelConfig();

  useEffect(() => {
    const ageVerifiedClickOutside = (event) => {
      if (isWeb()) return;
      if (ageVerContentRef.current) {
        const elem = event.target;

        if (elem.classList.contains('AgeVerifiedContainer')) {
          ageVerVisible.current = !ageVerVisible.current;
          ageVerContentRef.current.style.display = ageVerVisible.current ? 'block' : 'none';
        } else {
          ageVerContentRef.current.style.display = 'none';
          ageVerVisible.current = false;
        }
      }
    };
    document.addEventListener('click', ageVerifiedClickOutside);

    return () => {
      document.removeEventListener('click', ageVerifiedClickOutside);
    };
  }, []);

  const renderNav = () => {
    return (
      <nav className="NavBar d-flex align-center">
        {!desktopSearchVisible ? (
          <>
            <BrowseNavigation
              className='NavBar-Btn'
              baseText={t('BrowseNavigation.browse')}
            >
              <NavigationLinks links={browse_config} className='Dropdown-Item' />
            </BrowseNavigation>
            <NavigationLinks links={header_links} className='NavBar-Btn' />
          </>
        ) : (
          <i className="HeaderNavCollapseIndicator fas fa-bars" />
        )}

        {renderSearch()}
      </nav>
    );
  };

  const renderSearch = () => {
    return (
      <MainSearch
        toggleSearchFn={(val) => setDesktopSearchVisible(!val)}
        handleSearchDropdownValue={handleSearchDropdownValue}
      />
    );
  };

  const renderUserActions = () => {
    return (
      <div className="UserActions">
        {userLoggedIn ? (
          <UserAction
            text={t('MainDashboardHeader.signOut')}
            onClick={() => UsersController.signOutUser(t('MainDashboardHeader.loginOutMsg'))}
          />
        ) : (
          <UserAction
            text={t('MainDashboardHeader.joinNow')}
            onClick={() => {
              // get latest valid code
              const getStoredCode = getUserNATSCode();
              const joinURL = createJoinLinkFromLastCode(getStoredCode);
              navigate(joinURL);
            }}
          />
        )}
        {userLoggedIn && <div className="Separator">|</div>}
        {userLoggedIn ? (
          <UserAction text={t('MainDashboardHeader.myAccount')} onClick={() => showMyAccountPage()} />
        ) : (
          <div onClick={() => SignInController.showSignInDialog()} className="LoginWrapper">
            <UserAction text={t('MainDashboardHeader.login')} />
            <Arrow />
          </div>
        )}
      </div>
    );
  };

  const renderMobileSearch = () => {
    if (!desktopSearchVisible || isWeb()) return null;
    return <div className="MobileSearchWrapper">{renderSearch()}</div>;
  };

  const renderAgeVerIcon = () => {
    let bavCookie = getCookie('bav');
    if (!bavCookie) return null;

    try {
      bavCookie = decodeURIComponent(bavCookie);
      bavCookie = window.atob(bavCookie);
    } catch (err) {
      console.error('Decoding bav failed:', err);
    }
    const bavToLocalFormat = new Date(bavCookie * 1000).toLocaleString(); // timestamp
    const validBavDate = bavToLocalFormat !== 'Invalid Date' ? bavToLocalFormat : '';

    return (
      <span className="AgeVerifiedContainer">
        <AgeVerifiedIcon />
        <div className="AgeVerifiedContent" ref={ageVerContentRef}>
          <div>Age verification successful.</div>
          <div>Verification valid until:</div>
          <div>{validBavDate}</div>
        </div>
      </span>
    );
  };

  const renderMobileIcons = () => {
    return (
      <>
        <i
          className="MobileSearchBtn fa fa-search"
          onClick={() => {
            if (SignInController.isSignInDisplayed()) {
              SignInController.closeSignInDialog();
            }
            setDesktopSearchVisible((prevState) => !prevState);
          }}
        />

        <i
          className="MobileMenuBtn fa fa-bars cursor-pointer"
          onClick={() => {
            if (toggleMobileMenu()) {
              toggleMobileMenu();
            }
            setDesktopSearchVisible(false);
          }}
        />
      </>
    );
  };

  return (
    <header className="MainDashboardHeader d-flex align-center">
      <div className="d-flex align-center">
        <ApplicationLogo />
        {renderNav()} {/* hidden in mobile view*/}
      </div>

      <div className="d-flex align-center">
        {renderUserActions()} {/* hidden in mobile view*/}
        {renderMobileIcons()}
        {renderAgeVerIcon()}
      </div>

      {renderMobileSearch()}
    </header>
  );
};

MainDashboardHeader.displayName = 'MainDashboardHeader';

export default MainDashboardHeader;
